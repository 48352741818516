import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Link } from "react-router-dom";

import { useVictims } from "../../../data/VictimsDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  mainGrid: {
    marginTop: theme.spacing(1)
  },
  bigAvatar: {
    margin: -3,
    width: 60,
    height: 60
  },
  badgeMargin: {
    marginLeft: 0,
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: 0
  }
}));

export default function Today() {
  const classes = useStyles();
  const numbers = useSelector(state => state.numbers);
  const dispatch = useDispatch();
  const { getTodayStats } = useVictims();

  React.useEffect(
    () => {
      dispatch(getTodayStats());
    },
    // eslint-disable-next-line
    []
  );
  return (
    numbers.toDay.data !== undefined &&
    numbers.toDay.data.length > 0 && (
      <React.Fragment>
        <br />
        <br />
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" align="center">
              Today in history
            </Typography>
            <Grid container spacing={2} className={classes.mainGrid}>
              {numbers.toDay.data.map((cas, i) => (
                <Grid key={"to-day-victim-" + i} item xs={12} sm={6}>
                  <List component="nav">
                    <ListItem
                      key={"month-cas-" + i}
                      button
                      component={Link}
                      to={"/victim/" + cas.id}
                    >
                      <ListItemAvatar>
                        <Badge
                          className={classes.badgeMargin}
                          badgeContent={cas.count > 1 ? cas.count : null}
                          color="primary"
                        >
                          <Avatar
                            alt={cas.name}
                            src={cas.picture}
                            className={classes.bigAvatar}
                          />
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        primary={cas.name}
                        secondary={
                          <React.Fragment>
                            <Typography color="textSecondary" variant="body2">
                              {cas.formatted_date}
                            </Typography>
                            <Typography color="textSecondary" variant="caption">
                              {cas.type.name}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  );
}
