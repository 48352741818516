import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Loader from "../shared/Loader";
import { Link } from "react-router-dom";
import SearchBar from "material-ui-search-bar";

import { useVictims } from "../../data/VictimsDataProvider";
import { useTypes } from "../../data/TypesDataProvider";
import { useCategories } from "../../data/CategoriesDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cardGrid: {
    padding: `${theme.spacing(4)}px 0`
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  titleBar: {
    fontSize: "0.8125rem",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)"
  }
}));

export default function Home(params) {
  const classes = useStyles();
  const home = useSelector(state => state.home);
  const dispatch = useDispatch();
  const { getPublishedVictims } = useVictims();
  const { getTypeToTitle } = useTypes();
  const { getCategoryToTitle } = useCategories();

  const setTitle = () => {
    if (params.categoryId !== undefined) {
      dispatch(getCategoryToTitle(params.categoryId));
    } else if (params.typeId !== undefined) {
      dispatch(getTypeToTitle(params.typeId));
    } else if (params.yearId !== undefined) {
      dispatch({
        type: "SET_TITLE",
        title: "All forgotten victims (Year " + params.yearId + ")"
      });
    } else {
      dispatch({
        type: "SET_TITLE",
        title: "All forgotten victims"
      });
    }
  };

  React.useEffect(() => {
    setTitle();
    dispatch(getPublishedVictims(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {home.isLoading && <Loader backdrop />}
      <Header />
      <Grid container justifyContent="center">
        <Grid item xs={9}>
          <br />
          <SearchBar
            value={home.searchValue}
            placeholder={"Search here..."}
            onChange={searchValue =>
              dispatch({ type: "SET_SEARCH_VALUE", searchValue: searchValue })
            }
            onRequestSearch={searchValue =>
              dispatch({ type: "SET_SEARCH_VALUE", searchValue: searchValue })
            }
            onCancelSearch={() =>
              dispatch({ type: "SET_SEARCH_VALUE", searchValue: "" })
            }
            style={{
              margin: "0 auto",
              maxWidth: "90%"
            }}
          />
          <br />
          <Typography variant="h5" align="center">
            {home.title}
            {home.data !== undefined &&
              home.data.filter(
                victim =>
                  victim.name
                    .toLowerCase()
                    .includes(home.searchValue.toLowerCase()) ||
                  victim.short_story
                    .toLowerCase()
                    .includes(home.searchValue.toLowerCase())
              ).length === 0 && (
                <>
                  <br />
                  <Typography color="primary" variant="caption">
                    {"No information matching your seartch"}
                  </Typography>
                </>
              )}
          </Typography>
        </Grid>
      </Grid>

      <main>
        <div className={classNames(classes.layout, classes.cardGrid)}>
          {home.data !== undefined && (
            <Grid container spacing={3}>
              {home.data
                .filter(
                  victim =>
                    victim.name
                      .toLowerCase()
                      .includes(home.searchValue.toLowerCase()) ||
                    victim.short_story
                      .toLowerCase()
                      .includes(home.searchValue.toLowerCase())
                )
                .map(victim => (
                  <Grid item key={"victim-" + victim.id} sm={6} md={4} lg={3}>
                    <Card className={classes.card}>
                      <CardActionArea
                        component={Link}
                        to={"/victim/" + victim.id}
                      >
                        <ImageList cols={1} className={classes.gridList}>
                          <ImageListItem key={"victim-tile-" + victim.id}>
                            <ImageListItemBar
                              title={victim.date}
                              subtitle={
                                victim.count > 1 && victim.count + " victims"
                              }
                              classes={{
                                root: classes.titleBar
                              }}
                            />
                            {victim.picture !== "" && (
                              <CardMedia
                                component="img"
                                src={victim.picture}
                                title={victim.name}
                              />
                            )}
                          </ImageListItem>
                        </ImageList>
                        <CardContent className={classes.cardContent}>
                          <Typography gutterBottom variant="h6" component="h2">
                            {victim.name}
                          </Typography>
                          <Typography variant="body2" component="p">
                            {victim.short_story
                              .replace(/<[^>]+>/g, "")
                              .substring(0, 85) + "..."}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
}
