import React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DataTable from "../../shared/DataTable";
import RowMenu from "../../shared/RowMenu";

import { useUsers } from "../../../data/UsersDataProvider";
import { useSelector, useDispatch } from "react-redux";

export default function UsersList() {
  const { getUsers, getUser } = useUsers();
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);

  const editUser = (id = undefined) => {
    dispatch({
      type: "SET_USERS_LOADING",
      isLoading: true
    });

    if (id !== undefined) {
      dispatch(getUser(id)).then(resp => {
        dispatch({
          type: "SET_USERS_LOADING",
          isLoading: false
        });
        dispatch({ type: "SET_USER_FORM_OPEN", formOpen: true });
      });
    } else {
      dispatch({
        type: "SET_SELECTED_USER",
        selected: {}
      });
      dispatch({ type: "SET_USER_FORM_OPEN", formOpen: true });
    }
  };

  const columns = [
    {
      label: <Typography component="b">{"Name"}</Typography>,
      name: "name",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: <Typography component="b">{"Email"}</Typography>,
      name: "email",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: <Typography component="b">{"Last activity"}</Typography>,
      name: "formattedLastActivity",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: "Options",
      name: "buttons",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: value => ({
          style: { fontSize: "0px", textAlign: "right" }
        }),
        setCellProps: value => ({
          style: { textAlign: "right" }
        })
      }
    }
  ];

  // const rowMenu = item => [
  //   {
  //     id: "edit-" + item.id,
  //     type: "menu",
  //     label: "Update",
  //     confirm: undefined,
  //     onClick: () => {}
  //     // onClick: () => loadUserForEdit(item.id)
  //   },
  //   {
  //     id: "devider-" + item.id,
  //     type: "devider"
  //   },
  //   {
  //     id: "delete-" + item.id,
  //     type: "menu",
  //     label: "Delete",
  //     confirm: "Are you sure you want to delete this user",
  //     onClick: () => {}
  //     // onClick: () => deleteUser(item.id)
  //   }
  // ];

  const tableOptions = {
    customToolbar: () => {
      return (
        <React.Fragment>
          <Tooltip title={"Add new user"}>
            <IconButton
              onClick={() => {
                editUser();
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      );
    }
  };

  const rowFormat = row => {
    return {
      name: row.name,
      email: row.email,
      formattedLastActivity: (
        <Typography variant="caption" color="textSecondary" component="i">
          {row.formatted_last_activity}
        </Typography>
      ),
      buttons: (
        <RowMenu
          extraItems={[
            <IconButton
              key={"menu-item-edit-" + row.id}
              size={"small"}
              onClick={() => editUser(row.id)}
            >
              {<EditIcon fontSize={"small"} />}
            </IconButton>
          ]}
        />
      )
    };
  };

  React.useEffect(
    () => {
      dispatch({
        type: "SET_TABLE",
        payload: { title: "Users", columns: columns, rowFormat: rowFormat }
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <DataTable
      dataTable={users}
      dataFn={getUsers}
      tableOptions={tableOptions}
    />
  );
}
