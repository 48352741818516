import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Link } from "react-router-dom";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { useVictims } from "../../../data/VictimsDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  mainGrid: {
    marginTop: theme.spacing(1)
  },
  bigAvatar: {
    margin: -3,
    width: 60,
    height: 60
  },
  badgeMargin: {
    marginLeft: 0,
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: 0
  }
}));

export default function ThisMonth() {
  const classes = useStyles();
  const thisMonth = useSelector(state => state.numbers.thisMonth);
  const dispatch = useDispatch();
  const { getThisMonthStats } = useVictims();

  React.useEffect(
    () => {
      dispatch(getThisMonthStats());
    },
    // eslint-disable-next-line
    []
  );

  return (
    thisMonth.data !== undefined && (
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" align="center">
            Cases per month
          </Typography>
          <Typography variant="subtitle2" align="center">
            {thisMonth.data
              .filter(victim =>
                victim.formatted_date.includes(thisMonth.selectedMonth)
              )
              .reduce((prev, next) => prev + next.count, 0) +
              " victim(s) in " +
              thisMonth.data.filter(victim =>
                victim.formatted_date.includes(thisMonth.selectedMonth)
              ).length +
              " case(s)"}
          </Typography>
          <Grid container spacing={3} className={classes.mainGrid}>
            <Grid item xs={12} md={3}>
              <br />
              <FormControl className={classes.formControl}>
                <InputLabel>Choose month</InputLabel>
                <Select
                  value={thisMonth.selectedMonth}
                  onChange={e => {
                    dispatch({
                      type: "SET_SELECTED_MONTH",
                      selectedMonth: e.target.value
                    });
                  }}
                >
                  {thisMonth.months.map((period, i) => (
                    <MenuItem key={"month-cases-" + i} value={period.month}>
                      {period.month + " (" + period.count + ")"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={9}>
              <List component="nav">
                {thisMonth !== undefined &&
                  thisMonth.data
                    .filter(victim =>
                      victim.formatted_date.includes(thisMonth.selectedMonth)
                    )
                    .map((cas, i) => (
                      <ListItem
                        key={"month-cas-" + i}
                        button
                        component={Link}
                        to={"/victim/" + cas.id}
                      >
                        <ListItemAvatar>
                          <Badge
                            className={classes.badgeMargin}
                            badgeContent={cas.count > 1 ? cas.count : null}
                            color="primary"
                          >
                            <Avatar
                              alt={cas.name}
                              src={cas.picture}
                              className={classes.bigAvatar}
                            />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemText
                          disableTypography={true}
                          primary={cas.name}
                          secondary={
                            <React.Fragment>
                              <Typography color="textSecondary" variant="body2">
                                {cas.formatted_date}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                {cas.type}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  );
}
