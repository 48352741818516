import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { useTypes } from "../../data/TypesDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  toolbarMain: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  toolbarTitle: {
    flex: 1
  },
  list: {
    width: 250
  }
}));

export default function Header() {
  const classes = useStyles();
  const { types, application } = useSelector(state => state);
  const dispatch = useDispatch();
  const { getTypes } = useTypes();

  React.useEffect(
    () => {
      dispatch(getTypes());
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbarMain}>
        {types.data !== undefined && types.data.length > 0 && (
          <React.Fragment>
            <IconButton
              onClick={() =>
                dispatch({ type: "SET_DRAWER_OPEN", drawerOpen: true })
              }
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={"left"}
              open={application.drawerOpen}
              onClose={() =>
                dispatch({ type: "SET_DRAWER_OPEN", drawerOpen: false })
              }
            >
              <div
                className={classes.list}
                role="presentation"
                onClick={() =>
                  dispatch({ type: "SET_DRAWER_OPEN", drawerOpen: false })
                }
                onKeyDown={() =>
                  dispatch({ type: "SET_DRAWER_OPEN", drawerOpen: false })
                }
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          variant="button"
                          color="inherit"
                          align="center"
                        >
                          Victims
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem button component={Link} to={"/"}>
                    <ListItemText primary={"All"} />
                  </ListItem>
                  {types.data
                    .filter(type => type.cases_count > 0)
                    .map((type, i) => (
                      <ListItem
                        button
                        component={Link}
                        to={"/type/" + type.id}
                        key={"drawer-type-menu-" + i}
                      >
                        <ListItemText primary={type.name} />
                      </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                  <ListItem
                    button
                    key={"number"}
                    component={Link}
                    to="/numbers"
                  >
                    <ListItemText primary={"Numbers and maps"} />
                  </ListItem>
                  <ListItem button key={"about"} component={Link} to="/about">
                    <ListItemText primary={"About this Initiative"} />
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </React.Fragment>
        )}
        <Typography
          component="h2"
          variant="h4"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          Cry for Justice
        </Typography>
        <IconButton component={Link} to="/about">
          <InfoIcon />
        </IconButton>
      </Toolbar>
    </React.Fragment>
  );
}
