export const victims = (
  state = {
    title: undefined,
    columns: undefined,
    rowFormat: undefined,
    formOpen: false,
    data: undefined,
    selected: undefined,
    isLoading: undefined,
    types: undefined,
    categories: undefined,
    countries: undefined,
    error: ""
  },
  action
) => {
  switch (action.type) {
    case "SET_ADMIN_VICTIMS":
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case "SET_VICTIMS_LOADING":
      return {
        ...state,
        // data: undefined,
        isLoading: true
      };
    case "SET_TABLE":
      return {
        ...state,
        title: action.payload.title,
        columns: action.payload.columns,
        rowFormat: action.payload.rowFormat
      };
    case "SET_VICTIM_FORM_OPEN":
      return {
        ...state,
        formOpen: action.formOpen
      };
    case "SET_TYPES_OPTIONS":
      return {
        ...state,
        types: action.types
      };
    case "SET_CATEGORIES_OPTIONS":
      return {
        ...state,
        categories: action.categories
      };
    case "SET_COUNTRIES_OPTIONS":
      return {
        ...state,
        countries: action.countries
      };
    case "SET_SELECTED_VICTIM":
      return {
        ...state,
        selected: action.selected
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
