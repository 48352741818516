import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MoodBadTwoToneIcon from "@material-ui/icons/MoodBadTwoTone";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import VictimPopUp from "./VictimPopUp";
import { MapContainer, TileLayer, ZoomControl, useMap } from "react-leaflet";

import rwBoundaries from "../../../data/rwBoundaries.json";
import { useVictims } from "../../../data/VictimsDataProvider";
import { useSelector, useDispatch } from "react-redux";

import "leaflet/dist/leaflet.css";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const useStyles = makeStyles(theme => ({
  mainGrid: {
    marginTop: theme.spacing(1)
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

const pointToLayer = (feature, latlng) => {
  const smallIcon = L.DivIcon.extend({
    options: {
      className: "hiddable",
      iconSize: [0, 0],
      html: ReactDOMServer.renderToString(
        <MoodBadTwoToneIcon
          style={{
            fontSize: 12,
            position: "absolute",
            left: -6,
            top: -6
          }}
          htmlColor={
            [1, 2].includes(feature.properties.type.id) ? "#d9534f" : "#f0ad4e"
          }
        />
      )
    }
  });
  return L.marker(latlng, { icon: new smallIcon() });
};

const borderStyle = feature => {
  switch (feature.geometry.type) {
    case "Polygon":
    case "MultiPolygon":
      return {
        color: "#266696",
        weight: 3,
        fillColor: "transparent"
      };
    default:
      return {};
  }
};

function SetGeoJSONBounds({ mapFeatures, onEachFeature }) {
  const map = useMap();

  React.useMemo(() => {
    map.eachLayer(layer => {
      if (!!layer.toGeoJSON) {
        map.removeLayer(layer);
      }
    });

    const rwandaMapLayer = L.geoJson(rwBoundaries, {
      style: borderStyle,
      pointToLayer: pointToLayer
    }).addTo(map);

    L.geoJson(mapFeatures, {
      pointToLayer: pointToLayer,
      onEachFeature: onEachFeature
    }).addTo(map);
    rwandaMapLayer.getBounds()._southWest !== undefined &&
      map.fitBounds(rwandaMapLayer.getBounds());
  }, [mapFeatures, map]); // eslint-disable-line

  return null;
}

export default function RwMap() {
  const classes = useStyles();
  const geoMapped = useSelector(state => state.numbers.geoMapped);
  const dispatch = useDispatch();
  const { getMappedShotAtVictims } = useVictims();

  const onEachFeature = (feature, layer) => {
    const popupContent = ReactDOMServer.renderToString(
      <VictimPopUp victim={feature.properties} />
    );
    layer.bindPopup(popupContent, { autoPan: false, closeButton: false });
    layer.on({
      mouseover: e => {
        layer.openPopup();
      },
      mouseout: e => {
        layer.closePopup();
      },
      click: e => (window.location.href = "/victim/" + feature.properties.id)
    });
  };

  React.useEffect(
    () => {
      dispatch(getMappedShotAtVictims());
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" align="center">
          {geoMapped.data !== undefined &&
            geoMapped.data
              .filter(feature =>
                feature.properties.formatted_date.includes(
                  geoMapped.selectedYear
                )
              )
              .reduce((prev, next) => prev + next.properties.count, 0) +
              " victims in at least " +
              geoMapped.data.filter(feature =>
                feature.properties.formatted_date.includes(
                  geoMapped.selectedYear
                )
              ).length +
              " documented cases of use of lethal force by rwandan security personnel " +
              (geoMapped.selectedYear === ""
                ? " since 1994."
                : "in " + geoMapped.selectedYear)}
        </Typography>
        <Grid container spacing={3} className={classes.mainGrid}>
          {geoMapped.years !== undefined && (
            <Grid item xs={12} md={2}>
              <br />
              <List component="nav">
                <ListItem
                  button
                  onClick={() =>
                    dispatch({ type: "SET_SELECTED_YEAR", selectedYear: "" })
                  }
                >
                  <ListItemText primary="All victims" />
                </ListItem>
                {geoMapped.years.map(link => (
                  <ListItem
                    key={"grouped-map-" + link.year}
                    button
                    onClick={() =>
                      dispatch({
                        type: "SET_SELECTED_YEAR",
                        selectedYear: link.year
                      })
                    }
                  >
                    <ListItemText
                      primary={link.year + " (" + link.count + ")"}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
          <Grid item xs={12} md={10}>
            <React.Fragment>
              <MapContainer
                doubleClickZoom={false}
                scrollWheelZoom={false}
                zoomControl={false}
                style={{
                  width: "100%",
                  height: "800px",
                  padding: "0px"
                }}
              >
                <TileLayer
                  attribution="Click map maker for case details."
                  url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                />
                {geoMapped.data !== undefined && (
                  <SetGeoJSONBounds
                    {...{
                      mapFeatures: {
                        type: "FeatureCollection",
                        features: geoMapped.data.filter(feature =>
                          feature.properties.formatted_date.includes(
                            geoMapped.selectedYear
                          )
                        )
                      },
                      onEachFeature: onEachFeature
                    }}
                  />
                )}
                <ZoomControl position="bottomleft" />
              </MapContainer>
            </React.Fragment>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
