import React from "react";
import wretch from "wretch";

const VictimsDataContext = React.createContext();

function VictimsDataProvider(props) {
  const getPublishedVictims = params => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_VICTIMS", data: undefined });
    dispatch({ type: "SET_HOME_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims/published")
      .json(params)
      .post()
      .notFound(error =>
        dispatch({
          type: "SET_VICTIMS",
          data: []
        })
      )
      .json(resp => dispatch({ type: "SET_VICTIMS", data: resp }));
  };

  const getPublishedVictim = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_VICTIM_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims/" + id)
      .get()
      .json(resp => dispatch({ type: "SET_VICTIM", data: resp }));
  };

  const getCasesPerYear = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_CASES_BY_YEAR_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims/years/")
      .get()
      .json(resp => dispatch({ type: "SET_CASES_BY_YEAR", data: resp }));
  };

  const getMappedShotAtVictims = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_GEO_MAPPED_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims/shots/")
      .get()
      .json(resp => dispatch({ type: "SET_GEO_MAPPED", data: resp }));
  };

  const getThisMonthStats = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_THIS_MONTH_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims/month/")
      .get()
      .json(resp => dispatch({ type: "SET_THIS_MONTH", data: resp }));
  };

  const getTodayStats = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_TODAY_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims/today/")
      .get()
      .json(resp => dispatch({ type: "SET_TODAY", data: resp }));
  };

  const getAllVictims = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    // dispatch({ type: "SET_VICTIMS_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/victims")
      .headers(getState().auth.params)
      .get()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .json(resp => dispatch({ type: "SET_ADMIN_VICTIMS", data: resp }))
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const deleteVictim = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/victims/" + id)
      .headers(getState().auth.params)
      .delete()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .json(resp => {
        dispatch(getAllVictims()).then(response =>
          dispatch({
            type: "SET_TOAST",
            data: {
              open: true,
              status: "success",
              message: "Record deleted successfully!"
            }
          })
        );
      })
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const createUpdateVictim = params => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    params.id === undefined || params.id === ""
      ? await wretch(apiEndPoint + "/api/victims/")
          .headers(getState().auth.params)
          .json(params)
          .post()
          .unauthorized(error =>
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            })
          )
          .json(resp => {
            dispatch(getAllVictims()).then(response => {
              dispatch({ type: "SET_VICTIM_FORM_OPEN", formOpen: false });
              dispatch({
                type: "SET_TOAST",
                data: {
                  open: true,
                  status: "success",
                  message: "Victim info saved successfully!"
                }
              });
            });
          })
          .catch(error => {
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            });
          })
      : await wretch(apiEndPoint + "/api/victims/" + params.id)
          .headers(getState().auth.params)
          .json({ victim: params })
          .put()
          .unauthorized(error =>
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            })
          )
          .json(resp => {
            dispatch(getAllVictims()).then(response => {
              dispatch({ type: "SET_VICTIM_FORM_OPEN", formOpen: false });
              dispatch({
                type: "SET_TOAST",
                data: {
                  open: true,
                  status: "success",
                  message: "Victim info updated successfully!"
                }
              });
            });
          })
          .catch(error => {
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            });
          });
  };

  const getVictimCountries = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/countries/")
      .get()
      .json(resp =>
        dispatch({
          type: "SET_COUNTRIES_OPTIONS",
          countries: resp.map(cat => ({ value: cat.id, label: cat.name }))
        })
      )
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const getVictimForEdit = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/victims/edit/" + id)
      .headers(getState().auth.params)
      .get()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .json(resp =>
        dispatch({
          type: "SET_SELECTED_VICTIM",
          selected: resp
        })
      )
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const cloudinaryUploadImage = async (file, preset, url) => {
    return await wretch(url)
      .formData({
        upload_preset: preset,
        file: file
      })
      .post()
      .json(resp => ({
        data: {
          url: resp.secure_url,
          public_id: resp.public_id,
          width: 300,
          height: 200,
          alignment: "center", // or "center", "right"
          type: resp.resource_type // or "video"
        }
      }))
      .catch(error => error);
  };

  return (
    <VictimsDataContext.Provider
      value={{
        getPublishedVictims,
        getPublishedVictim,
        getCasesPerYear,
        getMappedShotAtVictims,
        getThisMonthStats,
        getTodayStats,
        getAllVictims,
        createUpdateVictim,
        deleteVictim,
        getVictimCountries,
        getVictimForEdit,
        cloudinaryUploadImage
      }}
      {...props}
    />
  );
}

function useVictims() {
  const context = React.useContext(VictimsDataContext);
  if (context === undefined) {
    throw new Error(`useVictims must be used within a VictimsDataProvider`);
  }
  return context;
}

export { VictimsDataProvider, useVictims };
