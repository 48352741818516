import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

import DataTable from "../../shared/DataTable";
import RowMenu from "../../shared/RowMenu";

import VictimForm from "./VictimForm";

import { useVictims } from "../../../data/VictimsDataProvider";
import { useTypes } from "../../../data/TypesDataProvider";
import { useCategories } from "../../../data/CategoriesDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  badgeMargin: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: 0
  }
}));

export default function VictimsList() {
  const classes = useStyles();
  const {
    getAllVictims,
    deleteVictim,
    getVictimCountries,
    getVictimForEdit,
    createUpdateVictim
  } = useVictims();
  const { getTypesForOptions } = useTypes();
  const { getCategoriesForOptions } = useCategories();
  const dispatch = useDispatch();
  const victims = useSelector(state => state.victims);

  const editVictim = (id = undefined) => {
    dispatch({
      type: "SET_VICTIMS_LOADING",
      isLoading: true
    });
    dispatch(getTypesForOptions()).then(resp =>
      dispatch(getCategoriesForOptions()).then(resp =>
        dispatch(getVictimCountries()).then(resp => {
          if (id !== undefined) {
            dispatch(getVictimForEdit(id)).then(resp => {
              dispatch({
                type: "SET_VICTIMS_LOADING",
                isLoading: false
              });
              dispatch({ type: "SET_VICTIM_FORM_OPEN", formOpen: true });
            });
          } else {
            dispatch({
              type: "SET_VICTIMS_LOADING",
              isLoading: false
            });
            dispatch({ type: "SET_VICTIM_FORM_OPEN", formOpen: true });
          }
        })
      )
    );
  };

  const columns = [
    {
      label: <Typography component="b">{"Name"}</Typography>,
      name: "name",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: <Typography component="b">{"Type"}</Typography>,
      name: "type",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: <Typography component="b">{"Country"}</Typography>,
      name: "country",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: <Typography component="b">{"Citizenship"}</Typography>,
      name: "citizenship",
      options: {
        filter: false,
        sort: false,
        searchable: true
      }
    },
    {
      label: "",
      name: "searchableName",
      options: {
        filter: false,
        sort: false,
        searchable: true,
        setCellHeaderProps: value => ({
          style: { fontSize: "0px", textAlign: "right" }
        }),
        setCellProps: value => ({
          style: { fontSize: "0px", textAlign: "right" }
        })
      }
    },
    {
      label: "Options",
      name: "buttons",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: value => ({
          style: { fontSize: "0px", textAlign: "right" }
        }),
        setCellProps: value => ({
          style: { textAlign: "right" }
        })
      }
    }
  ];

  const tableOptions = {
    customToolbar: () => {
      return (
        <React.Fragment>
          <Tooltip title={"Add new victim"}>
            <IconButton
              onClick={() => {
                editVictim();
              }}
            >
              <PersonAdd />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      );
    }
  };

  const rowMenu = item => [
    {
      id: "edit-" + item.id,
      type: "menu",
      label: "Update",
      confirm: undefined,
      onClick: () => editVictim(item.id)
    },
    {
      id: "devider-" + item.id,
      type: "devider"
    },
    {
      id: "delete-" + item.id,
      type: "menu",
      label: "Delete",
      confirm: "Are you sure you want to delete this record?",
      onClick: () => dispatch(deleteVictim(item.id))
    }
  ];

  const rowFormat = row => {
    const menuConfig = rowMenu(row);
    return {
      name: (
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <Badge
              className={classes.badgeMargin}
              badgeContent={row.count > 1 ? row.count : null}
              color="primary"
            >
              <Avatar alt={row.name} src={row.picture} />
            </Badge>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography variant="subtitle2">{row.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {row.formatted_date}
            </Typography>
          </Grid>
        </Grid>
      ),
      searchableName: row.name,
      type: row.type.name,
      country: row.country.name,
      citizenship: row.citizenship.name,
      buttons: (
        <RowMenu
          extraItems={[
            <IconButton
              key={"menu-item-edit-" + row.id}
              onClick={() =>
                dispatch(
                  createUpdateVictim({ id: row.id, published: !row.published })
                )
              }
            >
              {row.published ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ]}
          menus={menuConfig}
        />
      )
    };
  };

  React.useEffect(
    () => {
      dispatch({
        type: "SET_TABLE",
        payload: { title: "Victims", columns: columns, rowFormat: rowFormat }
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      {victims.formOpen && <VictimForm />}
      <DataTable
        dataTable={victims}
        dataFn={getAllVictims}
        tableOptions={tableOptions}
      />
    </React.Fragment>
  );
}
