import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";

import { Link } from "react-router-dom";

import { useVictims } from "../../data/VictimsDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  victimCategories: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function CasesByYear() {
  const classes = useStyles();
  const { casesByYear } = useSelector(state => state.application);
  const dispatch = useDispatch();
  const { getCasesPerYear } = useVictims();

  React.useEffect(
    () => {
      dispatch(getCasesPerYear());
    },
    // eslint-disable-next-line
    []
  );

  return casesByYear.data === undefined || casesByYear.isLoading === true ? (
    <LinearProgress />
  ) : (
    <React.Fragment>
      <br />
      <Typography variant="h6" gutterBottom>
        Cases per year
      </Typography>
      <List component="nav" className={classes.victimCategories}>
        {Object.keys(casesByYear.data).map((year, yi) => (
          <React.Fragment key={"cases-year" + year}>
            <ListItem
              button
              component={Link}
              to={"/year/" + Number.parseInt(year)}
            >
              <ListItemText primary={Number.parseInt(year)} />
              <ListItemSecondaryAction>
                <Typography gutterBottom variant="body1">
                  {casesByYear.data[year]}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            {Object.keys(casesByYear.data).length !== yi + 1 && (
              <Divider light />
            )}
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
}
