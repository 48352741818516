import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 35001,
    position: "absolute"
  }
}));

export default function Loader(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const children = (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems={props.type === "linear" ? "stretch" : "center"}
    >
      {props.message !== undefined && (
        <Grid item xs={12}>
          <Typography variant="caption" display="block">
            {props.message !== undefined && props.message}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {props.type === "linear" ? <LinearProgress /> : <CircularProgress />}
      </Grid>
    </Grid>
  );

  return props.backdrop || props.backdrop !== undefined ? (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onClick={() => {
        setOpen(false);
      }}
    >
      {children}
    </Backdrop>
  ) : (
    children
  );
}
