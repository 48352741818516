export const home = (
  state = {
    title: "All forgotten victims",
    data: undefined,
    searchValue: "",
    isLoading: false,
    error: ""
  },
  action
) => {
  switch (action.type) {
    case "SET_VICTIMS":
      return {
        ...state,
        data: action.data,
        isLoading: false,
        error: ""
      };
    case "SET_TITLE":
      return {
        ...state,
        title: action.title
      };
    case "SET_SEARCH_VALUE":
      return {
        ...state,
        searchValue: action.searchValue
      };
    case "SET_HOME_LOADING":
      return {
        ...state,
        data: undefined,
        isLoading: action.isLoading
      };
    default:
      return state;
  }
};
