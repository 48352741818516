import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(2),
    marginTop: "auto",
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography align="center" component="div" variant="caption">
        <Typography color="textSecondary" component="span" variant="caption">
          {"Every victim deserves Justice!"}
        </Typography>
        {" | "}
        <Typography
          className={classes.link}
          variant="caption"
          component={Link}
          to={"/numbers/"}
        >
          Numbers
        </Typography>
        {" | "}
        <Typography
          className={classes.link}
          variant="caption"
          component={Link}
          to={"/about/"}
        >
          About this Initiative
        </Typography>
        {" | "}
        <Typography
          className={classes.link}
          variant="caption"
          component={Link}
          to={"/login/"}
        >
          Management
        </Typography>
      </Typography>
    </footer>
  );
}
