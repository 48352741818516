export const auth = (
  state = {
    isConnecting: false,
    isAuthenticated: false,
    params: JSON.parse(localStorage.getItem("authParam")),
    userInfo: undefined,
    error: ""
  },
  action
) => {
  switch (action.type) {
    case "IS_CONNECTING":
      return {
        ...state,
        isConnecting: action.isConnecting,
        isAuthenticated: false
      };
    case "IS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isConnecting: false
      };
    case "SET_PARAMS":
      return {
        ...state,
        params: action.params
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.userInfo
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
