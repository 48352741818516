import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: 200,
    maxWidth: 200
  }
});

export default function VictimPopUp(props) {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.root}>
      {props.victim.picture !== undefined && props.victim.picture !== "" && (
        <CardMedia
          component="img"
          alt={props.victim.name}
          height="120"
          image={props.victim.picture}
          title={props.victim.name}
        />
      )}
      <CardContent>
        <Typography variant="body2" component="div">
          {props.victim.name}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="div">
          {props.victim.formatted_date}
        </Typography>
        {props.victim.count > 1 && (
          <Typography variant="caption" color="textSecondary" component="div">
            {props.victim.count + " victim(s)."}
          </Typography>
        )}
        <Typography variant="caption" color="textSecondary" component="div">
          {props.victim.type.name}
        </Typography>
      </CardContent>
    </Card>
  );
}
