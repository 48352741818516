import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Badge from "@material-ui/core/Badge";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Loader from "../shared/Loader";

import CasesByCategory from "../shared/CasesByCategory";
import CasesByYear from "../shared/CasesByYear";
import { Link } from "react-router-dom";

import { useVictims } from "../../data/VictimsDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  main: {
    marginBottom: theme.spacing(3)
  },
  relatedCasesRoot: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  mainGrid: {
    marginTop: theme.spacing(1)
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1)
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  victimCategories: {
    backgroundColor: theme.palette.background.paper
  },
  bigAvatar: {
    margin: -3,
    width: 60,
    height: 60
  },
  badgeMargin: {
    marginLeft: 0,
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: 0
  }
}));

export default function Victim(props) {
  const classes = useStyles();
  const { getPublishedVictim } = useVictims();
  const { victim, application, categories } = useSelector(state => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPublishedVictim(props.victimId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.victimId]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {(victim.isLoading ||
        application.casesByYear.isLoading ||
        categories.isLoading) && <Loader backdrop />}
      <Header />
      <main className={classes.main}>
        <div className={classes.layout}>
          {victim.data !== undefined && (
            <div>
              <Grid container spacing={3} className={classes.mainGrid}>
                <Grid item xs={12} md={8}>
                  {victim.data.name === undefined ? (
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      {"Record not found."}
                    </Typography>
                  ) : (
                    <React.Fragment>
                      <Typography gutterBottom variant="h5">
                        {victim.data.name}
                      </Typography>
                      <Typography gutterBottom variant="caption">
                        {victim.data.formatted_countdown_date}
                      </Typography>
                      <Card className={classes.card}>
                        {victim.data.picture !== undefined &&
                          victim.data.picture !== "" && (
                            <CardMedia
                              className={classes.cardMedia}
                              image={victim.data.picture}
                              title={victim.data.name}
                            />
                          )}
                      </Card>
                      <Typography
                        component={"div"}
                        variant="body2"
                        gutterBottom
                        dangerouslySetInnerHTML={{
                          __html: victim.data.short_story
                        }}
                      />

                      {victim.data.related_cases !== undefined &&
                        victim.data.related_cases.length > 0 && (
                          <React.Fragment>
                            <br />
                            <Typography gutterBottom variant="h6">
                              Related cases
                            </Typography>
                            <List className={classes.relatedCasesRoot}>
                              {victim.data.related_cases.map(
                                (related_case, index) =>
                                  related_case.id !== victim.data.id && (
                                    <React.Fragment
                                      key={"related-case-" + index}
                                    >
                                      <ListItem
                                        alignItems="flex-start"
                                        button
                                        component={Link}
                                        to={"/victim/" + related_case.id}
                                        key={
                                          "related-case-victim" + victim.data.id
                                        }
                                      >
                                        <ListItemAvatar>
                                          <Badge
                                            className={classes.badgeMargin}
                                            badgeContent={
                                              related_case.count > 1
                                                ? related_case.count
                                                : null
                                            }
                                            color="primary"
                                          >
                                            <Avatar
                                              alt={related_case.name}
                                              src={related_case.picture}
                                              className={classes.bigAvatar}
                                            />
                                          </Badge>
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={related_case.name}
                                          secondary={
                                            <React.Fragment>
                                              <Typography
                                                gutterBottom
                                                variant="caption"
                                              >
                                                {related_case.formatted_date}
                                              </Typography>
                                              <br />
                                              {related_case.type.name}
                                            </React.Fragment>
                                          }
                                        />
                                      </ListItem>
                                      {victim.data.related_cases.length !==
                                        index + 1 && <Divider light />}
                                    </React.Fragment>
                                  )
                              )}
                            </List>
                          </React.Fragment>
                        )}
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <CasesByCategory />
                  <CasesByYear />
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}
