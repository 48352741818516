import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";

import { Link } from "react-router-dom";

import { useCategories } from "../../data/CategoriesDataProvider";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  victimCategories: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function CasesByCategory() {
  const classes = useStyles();
  const categories = useSelector(state => state.categories);
  const dispatch = useDispatch();
  const { getCategories } = useCategories();

  React.useEffect(
    () => {
      dispatch(getCategories());
    },
    // eslint-disable-next-line
    []
  );

  return categories.data === undefined || categories.isLoading === true ? (
    <LinearProgress />
  ) : (
    <React.Fragment>
      <br />
      <Typography variant="h6" gutterBottom>
        Cases by category
      </Typography>
      <List component="nav" className={classes.victimCategories}>
        {categories.data
          .filter(category => category.cases_count > 0)
          .map((category, index) => (
            <React.Fragment key={"victims-category" + index}>
              <ListItem button component={Link} to={"/category/" + category.id}>
                <ListItemText primary={category.name} />
                <ListItemSecondaryAction>
                  <Typography gutterBottom variant="body1">
                    {category.cases_count}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              {categories.data.length !== index + 1 && <Divider light />}
            </React.Fragment>
          ))}
        <Divider />
        <Divider />
        <ListItem button component={Link} to={"/numbers/"}>
          <ListItemText primary="Numbers and maps" />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
