import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import CasesByCategory from "../shared/CasesByCategory";
import Header from "../shared/Header";
import Footer from "../shared/Footer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  mainGrid: {
    marginTop: theme.spacing(1)
  }
}));

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <main className={classes.root}>
        <div className={classes.layout}>
          {
            <div>
              <Grid container spacing={3} className={classes.mainGrid}>
                <Grid item xs={12} md={8}>
                  <Typography gutterBottom variant="h4">
                    About this Initiative
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    We are young Rwandans. Many of us are members of
                    associations that campaign for the respect of human rights
                    in Rwanda such as{" "}
                    <MuiLink
                      rel="noopener"
                      variant="body2"
                      href="http://www.jamboasbl.com"
                      target="_blank"
                    >
                      Jambo ASBL
                    </MuiLink>
                    ,{" "}
                    <MuiLink
                      rel="noopener"
                      variant="body2"
                      href="http://www.cliir.org"
                      target="_blank"
                    >
                      CLIIR
                    </MuiLink>{" "}
                    and{" "}
                    <MuiLink
                      rel="noopener"
                      variant="body2"
                      href="https://rwandansrights.org"
                      target="_blank"
                    >
                      Global Campaign for Rwandans Rights
                    </MuiLink>
                    .
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    After realizing that when the Rwandan government
                    assassinates, forces a person or group of people into exile
                    or disappearance, these acts are reported in the media for a
                    few days or a few weeks at most and then people stop talking
                    about them, the victim(s) and/or injustice committed are no
                    longer cited. They fall into oblivion and this only
                    strengthens whoever is behind these human rights violations.
                    Therefore, we decided to create a list or a compendium of
                    all the victims of human rights violations at the hands of
                    the RPF lead’s government.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Thus, a list of victims by type (assassinations, attempted
                    assassinations, political prisoners and massacres), and by
                    category of victims (businessmen, doctors, soldiers, human
                    rights defenders, politicians, etc.) began to emerge.
                  </Typography>
                  <Typography gutterBottom variant="h5">
                    Our purposes
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    First, Rwandan Lives Matter sends a message to the Rwandan
                    authorities by documenting their gruesome human rights
                    record and reminding them that Justice must be served for
                    these victims who are listed. They must not be forgotten.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Secondly, this initiative serves to show the world that in
                    Rwanda, the RPF government has killed, forced into exile or
                    disappearance countless victims since it came to power after
                    the civil war and genocide against the Tutsis in 1994. In
                    addition, it serves as an aide-memoire for those who defend
                    human rights for Rwandans or who are interested in human
                    rights or just want to be generally informed.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    We consider it useful to remind that post genocide
                    authorities have the responsibility to protect the citizens.
                    Instead of protecting its population, these authorities have
                    committed and continue to commit atrocities against the
                    population.
                  </Typography>
                  <Typography gutterBottom variant="h5">
                    Our Thanks
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    When this website, went public, on the June 28, 2019, we
                    have received so many messages of encouragement and advice;
                    we wish to extend our gratitude to all the people who
                    supported and/or are involved in this initiative.
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Because Rwandan lives matter and every victim deserves
                    Justice.
                  </Typography>
                  <Typography gutterBottom variant="h5">
                    Contact us
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    Constance Mutimukeye and/or Ruhumuza Mbonyumutwa
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <MailOutlineIcon color="primary" fontSize="small" />
                    <MuiLink
                      href="mailto:rwandanlivesmatter@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      rwandanlivesmatter@gmail.com
                    </MuiLink>
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <TwitterIcon color="primary" fontSize="small" />
                    <MuiLink
                      href="https://twitter.com/RwLivesMatter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @RwLivesMatter
                    </MuiLink>
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <FacebookIcon color="primary" fontSize="small" />
                    <MuiLink
                      href="https://www.facebook.com/rwandanlivesmatter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RwandanLivesMatter
                    </MuiLink>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CasesByCategory />
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </main>
      <Footer />
    </div>
  );
}
