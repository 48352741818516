export const categories = (
  state = {
    data: undefined,
    isLoading: false,
    error: ""
  },
  action
) => {
  switch (action.type) {
    case "SET_CATEGORIES":
      return {
        ...state,

        data: action.data,
        isLoading: false,
        error: ""
      };
    case "SET_CATEGORIES_LOADING":
      return {
        ...state,
        data: undefined,
        isLoading: action.isLoading
      };
    default:
      return state;
  }
};
