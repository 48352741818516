import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Toast() {
  const dispatch = useDispatch();
  const { application } = useSelector(state => state);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: "SET_TOAST",
      data: {
        open: false,
        status: undefined,
        message: undefined
      }
    });
  };

  return (
    application.reqMessage !== undefined && (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={application.toastOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={application.reqStatus}>
          {application.reqMessage}
        </Alert>
      </Snackbar>
    )
  );
}
