import React from "react";
import wretch from "wretch";

const CategoriesDataContext = React.createContext();

function CategoriesDataProvider(props) {
  const getCategories = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_CATEGORIES_LOADING", isLoading: true });
    return wretch(apiEndPoint + "/api/categories/")
      .get()
      .json(resp => dispatch({ type: "SET_CATEGORIES", data: resp }));
  };

  const getCategoriesForOptions = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    return wretch(apiEndPoint + "/api/categories/")
      .get()
      .json(resp =>
        dispatch({
          type: "SET_CATEGORIES_OPTIONS",
          categories: resp.map(cat => ({ value: cat.id, label: cat.name }))
        })
      );
  };

  const getCategory = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/categories/" + id)
      .get()
      .json(resp => dispatch({ type: "SET_CATEGORY", data: [] }))
      .catch(error => console.log(error));
  };

  const getCategoryToTitle = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/categories/" + id)
      .get()
      .notFound(error =>
        dispatch({
          type: "SET_TITLE",
          title: "Category not found"
        })
      )
      .json(resp =>
        dispatch({
          type: "SET_TITLE",
          title: resp.name + " (" + resp.cases_count + ")"
        })
      )
      .catch(error => console.log(error));
  };

  // const createUpdateCategory = params => async (
  //   dispatch,
  //   getState,
  //   { apiEndPoint, userAuthParams }
  // ) => {
  //   params.id === undefined || params.id === ""
  //     ? await wretch(apiEndPoint + "/api/types/")
  //         .headers(userAuthParams)
  //         .json(params)
  //         .post()
  //         .json(resp => dispatch({ type: "CREATE_TYPE", data: [] }))
  //         .catch(error => console.log(error))
  //     : await wretch(apiEndPoint + "/api/types/" + params.id)
  //         .headers(userAuthParams)
  //         .json(params)
  //         .put()
  //         .json(resp => dispatch({ type: "UPDATE_TYPE", data: [] }))
  //         .catch(error => console.log(error));
  // };

  return (
    <CategoriesDataContext.Provider
      value={{
        getCategories,
        getCategory,
        getCategoryToTitle,
        getCategoriesForOptions
      }}
      {...props}
    />
  );
}

function useCategories() {
  const context = React.useContext(CategoriesDataContext);
  if (context === undefined) {
    throw new Error(
      `useCategories must be used within a CategoriesDataProvider`
    );
  }
  return context;
}

export { CategoriesDataProvider, useCategories };
