import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const authParams = JSON.parse(localStorage.getItem("authParam"));

const apiEndPoint = process.env.REACT_APP_API;
const userAuthParams = authParams === null ? {} : authParams;

export default createStore(
  rootReducer,
  applyMiddleware(thunk.withExtraArgument({ apiEndPoint, userAuthParams }))
);
