export const application = (
  state = {
    drawerOpen: false,
    casesByYear: { data: undefined, isLoading: false, error: "" },
    toastOpen: false,
    reqStatus: undefined,
    reqMessage: undefined
  },
  action
) => {
  switch (action.type) {
    case "SET_DRAWER_OPEN":
      return { ...state, drawerOpen: action.drawerOpen };
    case "SET_CASES_BY_YEAR":
      return {
        ...state,
        casesByYear: {
          ...state.casesByYear,
          data: action.data,
          isLoading: false,
          error: ""
        }
      };
    case "SET_CASES_BY_YEAR_LOADING":
      return {
        ...state,
        casesByYear: {
          ...state.casesByYear,
          data: undefined,
          isLoading: action.isLoading
        }
      };
    case "SET_TOAST":
      return {
        ...state,
        toastOpen: action.data.open,
        reqStatus: action.data.status,
        reqMessage: action.data.message
      };
    default:
      return state;
  }
};
