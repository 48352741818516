import React from "react";
import MUIDataTable from "mui-datatables";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from "./Loader";
import { useDispatch } from "react-redux";

export default function DataTable({ dataTable, dataFn, tableOptions }) {
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(dataFn());
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      {dataTable.data === undefined ? (
        <Loader backdrop />
      ) : (
        <MUIDataTable
          title={
            <List dense disablePadding>
              <ListItem dense disableGutters>
                <ListItemText
                  primary={
                    <Typography component="h4" variant="h6" color="primary">
                      {dataTable.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="div"
                      variant="caption"
                      color="primary"
                    >
                      {dataTable.data.length !== undefined &&
                        dataTable.data.length}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  {dataTable.isLoading === true && (
                    <CircularProgress size={32} />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          }
          data={
            dataTable.rowFormat !== undefined
              ? dataTable.data.map(dataTable.rowFormat)
              : dataTable.data
          }
          columns={dataTable.columns}
          onPageChange={{}}
          options={{
            ...tableOptions,
            // page: dataTable.page - 1,
            count: dataTable.data.length,
            searchText: "",
            filter: false,
            sort: false,
            print: false,
            download: false,
            elevation: 1,
            searchPlaceholder: "Search here...",
            viewColumns: false,
            selectableRows: "none",
            selectableRowsHeader: false,
            responsive: "standard",
            rowsPerPage: 8,
            setTableProps: () => {
              return {
                padding: "normal",
                size: "small"
              };
            }
          }}
        />
      )}
    </React.Fragment>
  );
}
