import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";

export default function RowMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuItem = menu => {
    switch (menu.type) {
      case "link":
        return (
          <MenuItem
            key={menu.id}
            button
            component={Link}
            to={menu.url}
            onClick={() => {
              handleClose();
            }}
          >
            {menu.label}
          </MenuItem>
        );
      case "menu":
        return (
          <MenuItem
            key={menu.id}
            onClick={() => {
              menu.confirm === undefined
                ? menu.onClick()
                : window.confirm(menu.confirm) && menu.onClick();
              handleClose();
            }}
          >
            {menu.label}
          </MenuItem>
        );
      case "devider":
        return <Divider key={menu.id} />;
      default:
    }
  };

  return (
    <div>
      {props.extraItems !== undefined && props.extraItems}
      {props.menus !== undefined && (
        <>
          <IconButton
            aria-label="configuredMenu-options"
            aria-controls="configuredMenu-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size={props.iconSize === undefined ? "medium" : props.iconSize}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="configuredMenu-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {props.menus.map(menu => getMenuItem(menu))}
          </Menu>
        </>
      )}
    </div>
  );
}
