import wretch from "wretch";
const authParams = JSON.parse(localStorage.getItem("auth_params"));
class TypeData {
  getTypes() {
    return wretch(process.env.REACT_APP_API + "/api/types/")
      .get()
      .json()
      .catch(error => console.log(error));
  }

  getType(id) {
    return wretch(process.env.REACT_APP_API + "/api/types/" + id)
      .get()
      .json();
  }

  createUpdateType(params) {
    if (params.id === undefined || params.id === "") {
      return wretch(process.env.REACT_APP_API + "/api/types/")
        .headers({
          "access-token": authParams.accessToken,
          "token-type": authParams.tokenType,
          client: authParams.client,
          expiry: authParams.expiry,
          uid: authParams.uid
        })
        .json(params)
        .post()
        .res();
    } else {
      return wretch(process.env.REACT_APP_API + "/api/types/" + params.id)
        .headers({
          "access-token": authParams.accessToken,
          "token-type": authParams.tokenType,
          client: authParams.client,
          expiry: authParams.expiry,
          uid: authParams.uid
        })
        .json(params)
        .put()
        .res();
    }
  }
}
export default new TypeData();
