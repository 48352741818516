import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactGA from "react-ga";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
const customPalette = createTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: "#5d94c7",
      main: "#266696",
      dark: "#003c68",
      contrastText: "#fff"
    },
    secondary: {
      light: "#5d94c7",
      main: "#266696",
      dark: "#003c68",
      contrastText: "#fff"
    }
  },
  overrides: {
    MUIRichTextEditor: {
      editor: {
        height: "300px",
        maxHeight: "300px",
        overflow: "auto"
      }
    }
  }
});

ReactGA.initialize("UA-143022536-1");
ReactDOM.render(
  <MuiThemeProvider theme={customPalette}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
