import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import Home from "./components/home/Home";
import Victim from "./components/home/Victim";
import About from "./components/home/About";
import Numbers from "./components/home/numbers/Numbers";
import Dashboard from "./components/admin/dashboard/Dashboard";
import VictimsList from "./components/admin/victims/VictimsList";
import TypesList from "./components/admin/types/TypesList";
import UsersList from "./components/admin/users/UsersList";

import ProtectedApp from "./components/shared/ProtectedApp";

import { VictimsDataProvider } from "./data/VictimsDataProvider";
import { TypesDataProvider } from "./data/TypesDataProvider";
import { CategoriesDataProvider } from "./data/CategoriesDataProvider";
import NotFound from "./components/shared/NotFound";

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.href });
  ReactGA.pageview(location.href);
});

export default function App() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.href);
  }, [window.location.href]); // eslint-disable-line

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <VictimsDataProvider>
                <TypesDataProvider>
                  <CategoriesDataProvider>
                    <Home />
                  </CategoriesDataProvider>
                </TypesDataProvider>
              </VictimsDataProvider>
            )}
          />
          <Route
            exact
            path="/category/:categoryId"
            render={props => (
              <VictimsDataProvider>
                <TypesDataProvider>
                  <CategoriesDataProvider>
                    <Home categoryId={props.match.params.categoryId} />
                  </CategoriesDataProvider>
                </TypesDataProvider>
              </VictimsDataProvider>
            )}
          />
          <Route
            exact
            path="/type/:typeId"
            render={props => (
              <VictimsDataProvider>
                <TypesDataProvider>
                  <CategoriesDataProvider>
                    <Home typeId={props.match.params.typeId} />
                  </CategoriesDataProvider>
                </TypesDataProvider>
              </VictimsDataProvider>
            )}
          />
          <Route
            exact
            path="/year/:yearId"
            render={props => (
              <VictimsDataProvider>
                <TypesDataProvider>
                  <CategoriesDataProvider>
                    <Home yearId={props.match.params.yearId} />
                  </CategoriesDataProvider>
                </TypesDataProvider>
              </VictimsDataProvider>
            )}
          />
          <Route
            exact
            path="/about"
            render={props => (
              <CategoriesDataProvider>
                <TypesDataProvider>
                  <About />
                </TypesDataProvider>
              </CategoriesDataProvider>
            )}
          />
          <Route
            exact
            path="/numbers"
            render={props => (
              <VictimsDataProvider>
                <CategoriesDataProvider>
                  <TypesDataProvider>
                    <Numbers />
                  </TypesDataProvider>
                </CategoriesDataProvider>
              </VictimsDataProvider>
            )}
          />
          <Route
            exact
            path="/admin"
            render={props => (
              <ProtectedApp {...props} bodyComponent={<Dashboard />} />
            )}
          />
          <Route
            exact
            path="/login"
            render={props => (
              <ProtectedApp {...props} bodyComponent={<Dashboard />} />
            )}
          />
          <Route
            exact
            path="/victim/:victimId"
            render={props => (
              <VictimsDataProvider>
                <CategoriesDataProvider>
                  <TypesDataProvider>
                    <Victim victimId={props.match.params.victimId} />
                  </TypesDataProvider>
                </CategoriesDataProvider>
              </VictimsDataProvider>
            )}
          />
          <Route
            exact
            path="/admin/victims"
            render={props => (
              <ProtectedApp
                {...props}
                bodyComponent={
                  <VictimsDataProvider>
                    <CategoriesDataProvider>
                      <TypesDataProvider>
                        <VictimsList />
                      </TypesDataProvider>
                    </CategoriesDataProvider>
                  </VictimsDataProvider>
                }
              />
            )}
          />
          <Route
            exact
            path="/admin/types"
            render={props => (
              <ProtectedApp {...props} bodyComponent={<TypesList />} />
            )}
          />
          <Route
            exact
            path="/admin/users"
            render={props => (
              <ProtectedApp {...props} bodyComponent={<UsersList />} />
            )}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  );
}
