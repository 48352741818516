import React from "react";
import wretch from "wretch";

const TypesDataContext = React.createContext();

function TypesDataProvider(props) {
  const getTypes = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_TYPES_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/types/")
      .get()
      .json(resp => dispatch({ type: "SET_TYPES", data: resp }))
      .catch(error => console.log(error));
  };

  const getTypesForOptions = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/types/")
      .get()
      .json(resp =>
        dispatch({
          type: "SET_TYPES_OPTIONS",
          types: resp.map(type => ({ value: type.id, label: type.name }))
        })
      )
      .catch(error => console.log(error));
  };

  const getType = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/types/" + id)
      .get()
      .json(resp => dispatch({ type: "SET_TYPE", data: [] }))
      .catch(error => console.log(error));
  };

  const getTypeToTitle = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/types/" + id)
      .get()
      .notFound(error =>
        dispatch({
          type: "SET_TITLE",
          title: "Type not found"
        })
      )
      .json(resp =>
        dispatch({
          type: "SET_TITLE",
          title: resp.description + " (" + resp.cases_count + ")"
        })
      )
      .catch(error => console.log(error));
  };

  const createUpdateType = params => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    params.id === undefined || params.id === ""
      ? await wretch(apiEndPoint + "/api/types/")
          .headers(userAuthParams)
          .json(params)
          .post()
          .json(resp => dispatch({ type: "CREATE_TYPE", data: [] }))
          .catch(error => console.log(error))
      : await wretch(apiEndPoint + "/api/types/" + params.id)
          .headers(userAuthParams)
          .json(params)
          .put()
          .json(resp => dispatch({ type: "UPDATE_TYPE", data: [] }))
          .catch(error => console.log(error));
  };

  return (
    <TypesDataContext.Provider
      value={{
        getTypes,
        getType,
        getTypeToTitle,
        createUpdateType,
        getTypesForOptions
      }}
      {...props}
    />
  );
}

function useTypes() {
  const context = React.useContext(TypesDataContext);
  if (context === undefined) {
    throw new Error(`useTypes must be used within a TypesDataProvider`);
  }
  return context;
}

export { TypesDataProvider, useTypes };
