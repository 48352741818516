import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import withStyles from "@material-ui/core/styles/withStyles";

import TypeData from "../../../data/TypeData";

const styles = (theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

class NbrsByType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: undefined,
    };
    this.getTypes();
  }

  async getTypes() {
    const types = await TypeData.getTypes();
    this.setState({
      types: types,
    });
  }

  render() {
    const { classes } = this.props;

    if (this.state.types === undefined) {
      return <CircularProgress />;
    }

    return (
      <Grid container spacing={3}>
        {this.state.types.map((type) => (
          <Grid item key={"victim-" + type.id} xs={12} md={6} lg={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  style={{ color: "#" + type.color_code }}
                  variant="h3"
                  component="h4"
                  align="right"
                >
                  {type.cases_count}
                </Typography>

                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  {type.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
}

NbrsByType.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NbrsByType);
