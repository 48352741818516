import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField as MuiTextField } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import BackupIcon from "@material-ui/icons/Backup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Checkbox } from "formik-material-ui";
import {
  Autocomplete,
  Select,
  DatePicker
} from "material-ui-formik-components";
import format from "date-fns/format";

import { ContentState, convertToRaw } from "draft-js";
import { convertToHTML, convertFromHTML } from "draft-convert";

import MUIRichTextEditor from "mui-rte";
import UploadImagePopover from "./UploadImagePopover";

import { DropzoneArea } from "material-ui-dropzone";

import Loader from "../../shared/Loader";

import { useVictims } from "../../../data/VictimsDataProvider";
import { useSelector, useDispatch } from "react-redux";

const CLOUDINARY_UPLOAD_PRESET = "yearnforjustice";
const CLOUDINARY_UPLOAD_URL =
  "https://api.cloudinary.com/v1_1/yearnforjustice/image/upload";

const prepContent = contentMarkup => {
  // 1. Convert the HTML
  const contentHTML = convertFromHTML({
    htmlToBlock: (nodeName, node, lastList, inBlock) => {
      if (
        (nodeName === "figure" &&
          node.firstChild &&
          node.firstChild.nodeName === "IMG") ||
        (nodeName === "img" && inBlock !== "atomic")
      ) {
        return "atomic";
      }
      return null;
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === "a") {
        return createEntity("LINK", "MUTABLE", { url: node.href });
      }
      if (nodeName === "img") {
        return createEntity("IMAGE", "IMMUTABLE", {
          url: node.src,
          width: node.width,
          height: node.height,
          alignment: node.align,
          alt: node.alt,
          type: "image"
        });
      }
      return null;
    }
  })(contentMarkup);

  // 2. Create the ContentState object
  const state = ContentState.createFromBlockArray(
    contentHTML.getBlocksAsArray(),
    contentHTML.entityMap
  );

  // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
  return JSON.stringify(convertToRaw(state));
};

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 2,
    paddingBottom: 2
  },
  fileDropzone: {
    "& .MuiDropzonePreviewList-removeButton": {
      top: "35%",
      left: "40%"
    },
    "& .MuiDropzonePreviewList-image": {
      width: "300px !important",
      maxWidth: "300px !important",
      height: "auto !important"
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "12px !important"
    },
    "& .MuiDropzoneArea-icon": {
      width: "30px !important",
      height: "30px !important"
    }
  }
}));

export default function VictimForm() {
  const classes = useStyles();
  const ref = React.useRef(null);
  const [anchor, setAnchor] = React.useState(null);
  const [isWorkingPictures, setIsWorkingPictures] = React.useState(false);
  const { cloudinaryUploadImage, createUpdateVictim } = useVictims();
  const dispatch = useDispatch();
  const victims = useSelector(state => state.victims);

  const handleFileUpload = file => {
    return ref.current?.insertAtomicBlockAsync(
      "IMAGE",
      cloudinaryUploadImage(
        file,
        CLOUDINARY_UPLOAD_PRESET,
        CLOUDINARY_UPLOAD_URL
      ),
      "File is uploading..."
    );
  };

  const dropZoneAdd = async (files, setFieldValue) => {
    setIsWorkingPictures(true);
    const promises = [];

    files.forEach(file =>
      promises.push(
        cloudinaryUploadImage(
          file,
          CLOUDINARY_UPLOAD_PRESET,
          CLOUDINARY_UPLOAD_URL
        )
      )
    );

    await Promise.all(promises).then(results => {
      results.forEach(result => setFieldValue("picture", result.data.url));
      setIsWorkingPictures(false);
    });
  };

  const dropZoneDelete = (file, setFieldValue) => {
    setIsWorkingPictures(true);
    setFieldValue("picture", "");
    setIsWorkingPictures(false);
  };

  React.useEffect(
    () =>
      dispatch({
        type: "SET_VICTIMS_LOADING",
        isLoading: false
      }),
    [] // eslint-disable-line
  );

  return (
    victims.countries !== undefined &&
    victims.types !== undefined &&
    victims.categories !== undefined && (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={victims.formOpen}
        onClose={() =>
          dispatch({ type: "SET_VICTIM_FORM_OPEN", formOpen: false })
        }
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            id:
              victims.selected === undefined ? undefined : victims.selected.id,
            name: victims.selected === undefined ? "" : victims.selected.name,
            count: victims.selected === undefined ? "" : victims.selected.count,
            type_id:
              victims.selected === undefined ? "" : victims.selected.type_id,
            date:
              victims.selected === undefined
                ? new Date().toISOString().slice(0, 10)
                : victims.selected.date,
            country_id:
              victims.selected === undefined ? "" : victims.selected.country_id,
            citizenship_id:
              victims.selected === undefined
                ? ""
                : victims.selected.citizenship_id,
            geo_coords:
              victims.selected === undefined ? "" : victims.selected.geo_coords,
            rawStory: prepContent(
              victims.selected === undefined ? "" : victims.selected.short_story
            ),
            short_story: prepContent(
              victims.selected === undefined ? "" : victims.selected.short_story
            ),
            picture:
              victims.selected === undefined ? null : victims.selected.picture,
            tags: victims.selected === undefined ? "" : victims.selected.tags,
            categories:
              victims.selected === undefined
                ? []
                : victims.categories.filter(categ =>
                    victims.selected.categories
                      .map(cat => cat.id)
                      .includes(categ.value)
                  ),

            published:
              victims.selected === undefined
                ? false
                : victims.selected.published
          }}
          validate={values => {
            let errors = {};
            return errors;
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Victim's name is required!"),
            count: Yup.number()
              .positive()
              .min(1)
              .required("Count is required!"),
            date: Yup.date().required("Date is required"),
            type_id: Yup.number().required("Type required!"),
            categories: Yup.array().required("Choose at least one category"),
            country_id: Yup.number().required("Choose a country"),
            citizenship_id: Yup.number().required("Choose a country"),
            picture: Yup.string()
              .nullable()
              .required("Victim's picture is required!")
          })}
          onSubmit={(values, { setSubmitting }) => {
            let cleanValues = Object.assign({}, values);
            delete cleanValues.rawStory;

            cleanValues.victim_categories_attributes = cleanValues.categories.map(
              cat => ({ category_id: cat.value })
            );

            delete cleanValues.categories;

            cleanValues.short_story = convertToHTML({
              blockToHTML: block => {
                if (block.type === "PARAGRAPH") {
                  return <p />;
                }
              },
              entityToHTML: (entity, originalText) => {
                if (entity.type === "LINK") {
                  return `<a href="${entity.data.url}">${originalText}</a>`;
                }
                if (entity.type === "IMAGE") {
                  return `<img src="${entity.data.url}" alt="${entity.data.alt}" align="${entity.data.alignment}" height="${entity.data.height}" width="${entity.data.width}"/>`;
                }
                return originalText;
              }
            })(cleanValues.short_story.getCurrentContent());

            dispatch(createUpdateVictim(cleanValues));
          }}
        >
          {({
            values,
            isSubmitting,
            submitForm,
            handleSubmit,
            setFieldValue,
            errors
          }) => (
            <Form>
              <DialogTitle id="form-dialog-title">{values.name}</DialogTitle>
              <DialogContent>
                <Divider />
                <React.Fragment key={"victim-form"}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <Field
                        id="name"
                        name="name"
                        label="Victim Name"
                        required
                        fullWidth
                        value={values.name}
                        disabled={isSubmitting}
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        id="count"
                        name="count"
                        label="Number of Victims"
                        disabled={isSubmitting}
                        required
                        fullWidth
                        value={values.count}
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        id="type_id"
                        name="type_id"
                        label="Type of incident"
                        required
                        fullWidth
                        value={values.type_id}
                        options={victims.types}
                        disabled={isSubmitting}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        autoOk
                        id="date"
                        name="date"
                        label="Date of incident"
                        format="dd MMMM yyyy"
                        views={["year", "month", "date"]}
                        required
                        fullWidth
                        disableFuture={true}
                        value={values.date}
                        disabled={isSubmitting}
                        component={DatePicker}
                        onChange={date => {
                          setFieldValue("date", format(date, "yyyy-MM-dd"));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <br />
                      <Field
                        id="geo_coords"
                        name="geo_coords"
                        label="Geographic coordinates"
                        fullWidth
                        value={values.geo_coords}
                        disabled={isSubmitting}
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        multiple
                        id="categories"
                        name="categories"
                        value={values.categories}
                        required
                        fullWidth
                        options={victims.categories}
                        disabled={isSubmitting}
                        component={Autocomplete}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                          <MuiTextField {...params} label="Categories" />
                        )}
                        onChange={(e, v) => {
                          setFieldValue("categories", v);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <UploadImagePopover
                        anchor={anchor}
                        onSubmit={(data, insert) => {
                          if (insert && data.file) {
                            handleFileUpload(data.file);
                          }
                          setAnchor(null);
                        }}
                      />
                      <Card variant="outlined">
                        <CardContent>
                          <Box component="span">
                            <MUIRichTextEditor
                              ref={ref}
                              readOnly={isSubmitting}
                              defaultValue={values.rawStory}
                              onChange={editorState => {
                                setFieldValue("short_story", editorState);
                              }}
                              label={"Describe what happened"}
                              controls={[
                                "title",
                                "bold",
                                "italic",
                                "underline",
                                // "strikethrough",
                                // "highlight",
                                "undo",
                                "redo",
                                "link",
                                "media",
                                "imageUpload",
                                "numberList",
                                "bulletList",
                                "quote",
                                // "code",
                                "clear"
                              ]}
                              customControls={[
                                {
                                  name: "imageUpload",
                                  icon: <BackupIcon />,
                                  type: "callback",
                                  onClick: (_editorState, _name, anchor) => {
                                    setAnchor(anchor);
                                  }
                                }
                              ]}
                              draftEditorProps={{
                                handleDroppedFiles: (
                                  _selectionState,
                                  files
                                ) => {
                                  if (
                                    files.length &&
                                    files[0] !== undefined &&
                                    files[0].name !== undefined
                                  ) {
                                    handleFileUpload(files[0]);
                                    return "handled";
                                  }
                                  return "not-handled";
                                }
                              }}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Field
                        id="country_id"
                        name="country_id"
                        label="Country where it happened"
                        required
                        fullWidth
                        value={values.country_id}
                        options={victims.countries}
                        disabled={isSubmitting}
                        component={Select}
                      />

                      <Field
                        id="citizenship_id"
                        name="citizenship_id"
                        label="Victim's Citizenship"
                        required
                        fullWidth
                        value={values.citizenship_id}
                        options={victims.countries}
                        disabled={isSubmitting}
                        component={Select}
                      />

                      <FormControlLabel
                        control={
                          <Field
                            name="published"
                            value={true}
                            checked={values.published}
                            color="primary"
                            component={Checkbox}
                          />
                        }
                        label="Published"
                      />
                      <div className={classes.fileDropzone}>
                        {errors.picture !== undefined && (
                          <Typography variant="caption" color="error">
                            {errors.picture}
                          </Typography>
                        )}
                        {isWorkingPictures === true && (
                          <Loader
                            type="linear"
                            message={"Processing picture..."}
                          />
                        )}
                        <DropzoneArea
                          dropzoneText={"Drop the picture (s) here..."}
                          previewGridClasses={{
                            item: classes.preview
                          }}
                          initialFiles={[values.picture]}
                          onDelete={files =>
                            isSubmitting === false &&
                            dropZoneDelete(files, setFieldValue)
                          }
                          onDrop={files =>
                            isSubmitting === false &&
                            dropZoneAdd(files, setFieldValue)
                          }
                          filesLimit={1}
                          acceptedFiles={["image/jpeg", "image/png"]}
                          maxFileSize={5000000}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Field
                        id="tags"
                        name="tags"
                        label="Tags"
                        fullWidth
                        disabled={isSubmitting}
                        value={values.tags}
                        component={TextField}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    dispatch({ type: "SET_VICTIM_FORM_OPEN", formOpen: false })
                  }
                  color="primary"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  );
}
