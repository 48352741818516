import React from "react";
import wretch from "wretch";

const UserDataContext = React.createContext();

function UserAuthProvider(props) {
  const logIn = params => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "IS_CONNECTING", isConnecting: true });
    await wretch(apiEndPoint + "/api/users/sign_in/")
      .json(params)
      .post()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .res(response => {
        if ([200, 201].includes(response.status)) {
          // console.log(response);
          const tokens = {
            "access-token": response.headers.get("access-token"),
            "token-type": response.headers.get("token-type"),
            client: response.headers.get("client"),
            expiry: response.headers.get("expiry"),
            uid: response.headers.get("uid")
          };
          dispatch({
            type: "SET_PARAMS",
            params: tokens
          });
          localStorage.setItem("authParam", JSON.stringify(tokens));
        } else {
          // console.log(response);
          // Do what ?
        }
        return response.json(); // return this so you can then...
      })
      .then(response => {
        dispatch({
          type: "SET_USER_INFO",
          userInfo: response.data
        });
        dispatch({
          type: "IS_AUTHENTICATED",
          isAuthenticated: true
        });

        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "success",
            message: "You have successfully logged in to Rwandan Lives Matter"
          }
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const checkAuthStatus = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    // console.log("Check", userAuthParams, getState().auth.params);
    dispatch({ type: "IS_CONNECTING", isConnecting: true });
    await wretch(apiEndPoint + "/api/users/me")
      .headers(getState().auth.params)
      .post()
      .unauthorized(error => {
        localStorage.removeItem("authParam");
        dispatch({
          type: "SET_USER_INFO",
          userInfo: undefined
        });
        dispatch({
          type: "IS_AUTHENTICATED",
          isAuthenticated: false
        });
        dispatch({
          type: "SET_PARAMS",
          params: null
        });
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      })
      .json(response => {
        dispatch({
          type: "SET_USER_INFO",
          userInfo: response.data
        });
        dispatch({
          type: "IS_AUTHENTICATED",
          isAuthenticated: true
        });
      })
      .catch(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      );
  };

  const logOut = () => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    // console.log(userAuthParams, getState().auth.params);
    await wretch(apiEndPoint + "/api/users/sign_out")
      .headers(getState().auth.params)
      .delete()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .res(response => {
        if ([200, 201].includes(response.status)) {
          localStorage.removeItem("authParam");
          dispatch({
            type: "SET_USER_INFO",
            userInfo: undefined
          });
          dispatch({
            type: "IS_AUTHENTICATED",
            isAuthenticated: false
          });
          dispatch({
            type: "SET_PARAMS",
            params: null
          });

          dispatch({
            type: "SET_TOAST",
            data: {
              open: true,
              status: "info",
              message: "You have successfully logged out"
            }
          });
        } else {
          // console.log(response);
          // Do what ?
        }
      })
      .catch(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      );
  };

  return (
    <UserDataContext.Provider
      value={{
        logIn,
        logOut,
        checkAuthStatus
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(UserDataContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a UserAuthProvider`);
  }
  return context;
}

export { UserAuthProvider, useAuth };
