import { combineReducers } from "redux";
import { application } from "./application";
import { categories } from "./categories";
import { home } from "./home";
import { numbers } from "./numbers";
import { types } from "./types";
import { victim } from "./victim";
import { auth } from "./auth";
import { users } from "./users";
import { victims } from "./victims";

export default combineReducers({
  application,
  categories,
  home,
  numbers,
  types,
  victim,
  auth,
  users,
  victims
});
