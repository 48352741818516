export const numbers = (
  state = {
    toDay: { data: undefined, isLoading: false, error: "" },
    thisMonth: {
      data: undefined,
      months: undefined,
      selectedMonth: undefined,
      isLoading: false,
      error: ""
    },
    geoMapped: {
      data: undefined,
      years: undefined,
      selectedYear: "",
      isLoading: false,
      error: ""
    }
  },
  action
) => {
  switch (action.type) {
    case "SET_TODAY":
      return {
        ...state,
        toDay: {
          ...state.toDay,
          data: action.data,
          isLoading: false,
          error: ""
        }
      };
    case "SET_TODAY_LOADING":
      return {
        ...state,
        toDay: {
          ...state.toDay,
          data: undefined,
          isLoading: action.isLoading,
          error: ""
        }
      };
    case "SET_THIS_MONTH":
      return {
        ...state,
        thisMonth: {
          ...state.thisMonth,
          data: action.data.victims,
          months: action.data.links,
          selectedMonth: action.data.links[0].month,
          isLoading: false,
          error: ""
        }
      };
    case "SET_THIS_MONTH_LOADING":
      return {
        ...state,
        thisMonth: {
          ...state.thisMonth,
          data: undefined,
          months: undefined,
          selectedMonth: undefined,
          isLoading: action.isLoading,
          error: ""
        }
      };
    case "SET_GEO_MAPPED":
      return {
        ...state,
        geoMapped: {
          ...state.geoMapped,
          data: action.data.features,
          years: action.data.links,
          isLoading: false,
          error: ""
        }
      };
    case "SET_GEO_MAPPED_LOADING":
      return {
        ...state,
        geoMapped: {
          ...state.geoMapped,
          data: undefined,
          years: undefined,
          selectedYear: "",
          isLoading: action.isLoading,
          error: ""
        }
      };
    case "SET_SELECTED_YEAR":
      return {
        ...state,
        geoMapped: {
          ...state.geoMapped,
          selectedYear: action.selectedYear
        }
      };
    case "SET_SELECTED_MONTH":
      return {
        ...state,
        thisMonth: {
          ...state.thisMonth,
          selectedMonth: action.selectedMonth
        }
      };
    default:
      return state;
  }
};
