import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";

import TypeData from "../../../data/TypeData";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function TypesList() {
  const classes = useStyles();
  const [typesInfo, setTypesInfo] = React.useState([{ types: undefined }]);

  const getTypes = async () => {
    const rawTypes = await TypeData.getTypes();

    if (rawTypes !== undefined) {
      setTypesInfo({
        types: rawTypes.map((rawType) => ({
          name: rawType.name,
          description: rawType.description,
          casesCount: rawType.cases_count,
          buttons: (
            <React.Fragment>
              <IconButton
                aria-label="Edit"
                size="small"
                component={Link}
                to={"/admin/types/edit/" + rawType.id}
              >
                <EditIcon />
              </IconButton>
              <IconButton aria-label="Delete" size="small">
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          ),
        })),
      });
    }
  };

  React.useEffect(() => {
    getTypes();
  }, []);

  return typesInfo.types === undefined ? (
    <Typography align="center" component={"div"}>
      <br />
      <br />
      <CircularProgress />
    </Typography>
  ) : (
    <React.Fragment>
      <MUIDataTable
        title={"Types"}
        data={typesInfo.types}
        columns={[
          {
            label: "Name",
            name: "name",
            options: {
              filter: false,
              sort: false,
              searchable: true,
            },
          },
          {
            label: "Description",
            name: "description",
            options: {
              filter: false,
              sort: false,
              searchable: true,
            },
          },
          {
            label: "Total cases",
            name: "casesCount",
            options: {
              filter: false,
              sort: false,
              searchable: true,
            },
          },
          {
            label: "Options",
            name: "buttons",
            options: {
              filter: false,
              sort: false,
              searchable: true,
            },
          },
        ]}
        options={{
          filter: false,
          sort: false,
          print: false,
          download: false,
          viewColumns: false,
          selectableRows: "none",
          rowsPerPage: 8,
          elevation: 1,
        }}
      />
      {
        <Fab className={classes.fab} color="primary" onClick={() => {}}>
          <AddIcon />
        </Fab>
      }
    </React.Fragment>
  );
}
