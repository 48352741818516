export const users = (
  state = {
    title: undefined,
    columns: undefined,
    rowFormat: undefined,
    formOpen: false,
    data: undefined,
    selected: undefined,
    isLoading: undefined,
    error: ""
  },
  action
) => {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case "SET_USERS_LOADING":
      return {
        ...state,
        // data: undefined,
        isLoading: true
      };
    case "SET_TABLE":
      return {
        ...state,
        title: action.payload.title,
        columns: action.payload.columns,
        rowFormat: action.payload.rowFormat
      };
    case "SET_USER_FORM_OPEN":
      return {
        ...state,
        formOpen: action.formOpen
      };
    case "SET_SELECTED_USER":
      return {
        ...state,
        selected: action.selected
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
