import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import RwMap from "./RwMap";
import ThisMonth from "./ThisMonth";
import Today from "./Today";

import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import Loader from "../../shared/Loader";

import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  main: {
    marginBottom: theme.spacing(3)
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }
}));

export default function Numbers() {
  const classes = useStyles();
  const numbers = useSelector(state => state.numbers);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <main className={classes.main}>
        {(numbers.toDay.isLoading ||
          numbers.thisMonth.isLoading ||
          numbers.geoMapped.isLoading) && <Loader backdrop />}
        <div className={classes.layout}>
          <Today />
          <br />
          <br />
          <ThisMonth />
          <br />
          <br />
          <RwMap />
        </div>
      </main>
      <Footer />
    </div>
  );
}
