import React from "react";
import wretch from "wretch";

const UsersDataContext = React.createContext();

function UsersDataProvider(props) {
  const getUsers = params => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    dispatch({ type: "SET_USERS_LOADING", isLoading: true });
    await wretch(apiEndPoint + "/api/users")
      .headers(getState().auth.params)
      .get()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .json(resp => dispatch({ type: "SET_USERS", data: resp }))
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const getUser = id => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    await wretch(apiEndPoint + "/api/users/" + id)
      .headers(getState().auth.params)
      .get()
      .unauthorized(error =>
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        })
      )
      .json(resp => dispatch({ type: "SET_SELECTED_USER", selected: resp }))
      .catch(error => {
        dispatch({
          type: "SET_TOAST",
          data: {
            open: true,
            status: "error",
            message: JSON.parse(error.message).errors.join(". ")
          }
        });
      });
  };

  const createUpdateUser = params => async (
    dispatch,
    getState,
    { apiEndPoint, userAuthParams }
  ) => {
    params.id === undefined || params.id === ""
      ? await wretch(apiEndPoint + "/api/users/")
          .headers(getState().auth.params)
          .json(params)
          .post()
          .unauthorized(error =>
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            })
          )
          .json(resp =>
            dispatch(getUsers()).then(response => {
              dispatch({ type: "SET_USER_FORM_OPEN", formOpen: false });
              dispatch({
                type: "SET_TOAST",
                data: {
                  open: true,
                  status: "success",
                  message: "User info saved successfully!"
                }
              });
            })
          )
          .catch(error => {
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            });
          })
      : await wretch(apiEndPoint + "/api/users/" + params.id)
          .headers(getState().auth.params)
          .json({ user: params })
          .put()
          .unauthorized(error =>
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            })
          )
          .json(resp =>
            dispatch(getUsers()).then(response => {
              dispatch({ type: "SET_USER_FORM_OPEN", formOpen: false });
              dispatch({
                type: "SET_TOAST",
                data: {
                  open: true,
                  status: "success",
                  message: "User info updated successfully!"
                }
              });
            })
          )
          .catch(error => {
            dispatch({
              type: "SET_TOAST",
              data: {
                open: true,
                status: "error",
                message: JSON.parse(error.message).errors.join(". ")
              }
            });
          });
  };

  return (
    <UsersDataContext.Provider
      value={{
        getUsers,
        getUser,
        createUpdateUser
      }}
      {...props}
    />
  );
}

function useUsers() {
  const context = React.useContext(UsersDataContext);
  if (context === undefined) {
    throw new Error(`useUsers must be used within a UsersDataProvider`);
  }
  return context;
}

export { UsersDataProvider, useUsers };
