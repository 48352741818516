import React from "react";
import { UserAuthProvider } from "../../data/UserAuthProvider";
import { TypesDataProvider } from "../../data/TypesDataProvider";
import { UsersDataProvider } from "../../data/UsersDataProvider";
import Loader from "./Loader";
import Toast from "./Toast";

import { useSelector } from "react-redux";
const Admin = React.lazy(() => import("./Admin"));
const Login = React.lazy(() => import("./Login"));

export default function ProtectedApp(props) {
  const auth = useSelector(state => state.auth);
  return (
    <React.Suspense fallback={<Loader backdrop />}>
      <UserAuthProvider>
        <Toast />
        {auth.params === null ? (
          <TypesDataProvider>
            <Login />
          </TypesDataProvider>
        ) : (
          <UsersDataProvider>
            <Admin bodyComponent={props.bodyComponent} />
          </UsersDataProvider>
        )}
      </UserAuthProvider>
    </React.Suspense>
  );
}
